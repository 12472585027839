export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/yelp_white.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/facebook_white.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/zomato_white.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/instagram_white.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1591377852/general/cflogo_black.png'
export const contactbg =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395162/sushioyama/contactbg.jpg'
export const locationMap =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395170/sushioyama/locationMap.jpg'
export const logoTitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1602222261/sushimodo/webLogoTitle.png'
export const promotions1 =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395174/sushioyama/promotions1.jpg'
export const promotions3 =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395174/sushioyama/promotions3.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1602224444/sushimodo/mobileContactbg.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395173/sushioyama/mobileGallery.jpg'
export const mobileMap =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395170/sushioyama/mobileMap.jpg'
export const mobilePromotion1 =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395173/sushioyama/mobilePromotion.jpg'
export const mobilePromotion2 =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395173/sushioyama/mobilePromotion2.jpg'
export const mobilePromotion3 =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395173/sushioyama/mobilePromotion3.jpg'
export const aboutText =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395160/sushioyama/aboutText.png'
export const foodGallery =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395162/sushioyama/foodGallery.jpg'
export const hero =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395165/sushioyama/hero.jpg'
export const logo =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395162/sushioyama/headerLogo.png'
export const interior =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395168/sushioyama/interior.jpg'
export const mobileAbout =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395172/sushioyama/mobileAbout.jpg'
export const mobileHero =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395173/sushioyama/mobileHero.jpg'
export const orderPickupButton =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610395173/sushioyama/orderPickupButton.png'
export const johnhouseHistory =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1606944661/sushioyama/Johnson_House_History.pdf'

export { default as notice } from './notice.png'
